import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import ListGroup from 'react-bootstrap/ListGroup'

import Layout from '../../../components/layout'
import PDFsNav from '../../../components/pdfs-nav'
import ResourcesNav from '../../../components/resources-nav'
import LookingForMore from '../../../components/looking-for-more'

export default class FunPages extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const resources = get(this, 'props.data.allContentfulResource.edges')
    const metaDescription = `Fun Pages PDFs for teaching Math in PreK, Kindergarten, Elementary. Free teaching resources from ${siteTitle}`
    return (
      <Layout pageTitle="Fun Pages" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <PDFsNav path={this.props.path} />
        <h1>Fun Pages</h1>
        <ListGroup>
          {resources.map(
            ({ node }, i) =>
              node.file && (
                <ListGroup.Item key={i} as="a" href={node.file.file.url}>
                  {node.title}
                </ListGroup.Item>
              )
          )}
        </ListGroup>
        <hr />
        <LookingForMore />
      </Layout>
    )
  }
}

export const Query = graphql`
  query FunPagesQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulResource(
      filter: { collections: { eq: "fun-pages-pdfs" } }
      sort: { fields: createdAt }
    ) {
      edges {
        node {
          slug
          title
          file {
            file {
              url
            }
          }
        }
      }
    }
  }
`
